import React, { useEffect, useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { CSSTransition } from 'react-transition-group';
import { Container, Typography } from '@mui/material';

import { getNameAccordingToInsuranceCategory } from '../../utils/customerUtils';
import {
  GET_CUSTOMER,
  GET_CUSTOMERS,
  LOCAL_SHOW_ONBOARDS,
  switchCustomer,
  TOGGLE_SHOW_ONBOARD,
  useReady,
} from '../../shared';

import { PageTitle } from '../common';
import { ErrorPlaceholder, SelectUserItem } from '../ui';
import { StyledVerticalContent } from './SelectUser.styled';

export default function SelectUser() {
  const history = useHistory();
  const intl = useIntl();

  const [uuid, setUuid] = useState(null);
  const [sentUuid, setSentUuid] = useState(false);

  const [promises, setPromises] = useState([]);
  const [didMutate, setDidMutate] = useState(false);
  const [disableButtons, setDisableButtons] = useState(false);

  const { data: customerData, loading: customerDataLoading } = useQuery(GET_CUSTOMER);
  const { data: allCustomersData, loading: allCustomersLoading, error } = useQuery(GET_CUSTOMERS);

  const isCustomersDataLoading = customerDataLoading || allCustomersLoading;
  const currentCustomer = customerData?.customer;
  const customers = Array.isArray(allCustomersData?.customers)
    ? [...allCustomersData.customers]
    : [];

  const showOnboardsQuery = useQuery(LOCAL_SHOW_ONBOARDS);

  const client = useApolloClient();

  const ready = useReady();

  const setChildUuid = (childUuid) => {
    setUuid(childUuid);
    setDisableButtons(true);
  };

  const inactiveContractPlaceholder = intl.formatMessage({ id: 'contract.placeholder.inactive' });

  const sortCustomers = (customerA, customerB) => {
    // show 'active' contracts first
    return customerA?.endedContract?.localeCompare(customerB?.endedContract);
  };

  useEffect(() => {
    const switchCustomerToUuid = switchCustomer(client, history);

    if (!customerDataLoading && !!uuid && !sentUuid) {
      setSentUuid(true);
      if (currentCustomer) {
        switchCustomerToUuid(uuid);
      }
    }
  }, [uuid, sentUuid, customerDataLoading, currentCustomer, client, history]);

  useEffect(() => {
    if (showOnboardsQuery?.loading) return;
    if (!promises.length && showOnboardsQuery?.data?.showOnboards) {
      const showOnboards = showOnboardsQuery?.data?.showOnboards;
      const promisesArr = showOnboards.map((onboard) =>
        client.mutate({
          mutation: TOGGLE_SHOW_ONBOARD,
          variables: {
            name: onboard?.name,
            show: false,
          },
        })
      );
      setPromises(promisesArr);
    }

    if (promises.length && !didMutate) {
      Promise.all(promises)
        .then(() => {
          setDidMutate(true);
        })
        .catch(() => {});
    }
  }, [showOnboardsQuery, client, promises, didMutate]);

  if (!isCustomersDataLoading && !!customers) {
    return (
      <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
        <Container>
          <PageTitle>
            <Typography variant="h1">
              <FormattedMessage id="select.customer.insured.title" />
            </Typography>
          </PageTitle>

          <StyledVerticalContent>
            <Typography variant="p">
              <FormattedMessage id="select.customer.insured.note" />
            </Typography>

            {customers.length &&
              customers.sort(sortCustomers).map((customer) => {
                const {
                  uuid: customerUuid,
                  endedContract,
                  insuranceCategory,
                  policyName,
                } = customer || {};

                const isContractEnded = endedContract === 'true';

                return (
                  <SelectUserItem
                    key={customerUuid}
                    uuid={customerUuid}
                    name={getNameAccordingToInsuranceCategory(customer)}
                    policyName={isContractEnded ? inactiveContractPlaceholder : policyName}
                    insuranceCategory={insuranceCategory}
                    isContractEnded={isContractEnded}
                    setChildUuid={setChildUuid}
                    disabled={disableButtons}
                  />
                );
              })}
          </StyledVerticalContent>
        </Container>
      </CSSTransition>
    );
  }

  if (error) {
    return <ErrorPlaceholder />;
  }

  return null;
}
