import React, { useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { CSSTransition } from 'react-transition-group';

import { useReady, GET_CUSTOMER, VITOLO_REFERRAL_TERMS_LINK } from '../../../../shared';
import { JOIN_REFERRAL_PROGRAM } from '../../../../graphql/mutations/referrals';

import VitoloReferralSteps from './VitoloReferralSteps';
import VitoloReferralShare from './VitoloReferralShare';
import { Separator } from '../../../../components/common';
import { ErrorPlaceholder, Loading } from '../../../../components/ui';
import { StyledReferralCard, StyledReferralFooterText } from '../ReferralPage.styled';

// replaces <a></a> in the translation Id with text and link passed to it
const renderAnchorTag = (chunk, link) => (
  <a target="_blank" rel="noopener noreferrer" href={link}>
    {chunk}
  </a>
);

function VitoloReferralInformation() {
  const ready = useReady();
  const transitionTimeout = 500;

  const { data: customerData, loading: customerLoading } = useQuery(GET_CUSTOMER);
  const customer = customerData?.customer;

  const [
    generateHealthReferralCode,
    { loading: referralCodeLoading, error: referralCodeGenerationError },
  ] = useMutation(JOIN_REFERRAL_PROGRAM, {
    refetchQueries: [{ query: GET_CUSTOMER }],
  });

  // Generate referral code if customer does not have one
  useEffect(() => {
    const hasReferralCode = !!customer?.referralCode;

    if (!hasReferralCode) {
      generateHealthReferralCode();
    }
  }, [customer, customerLoading, generateHealthReferralCode]);

  if (referralCodeLoading) return <Loading showLogo />;

  if ((!customerLoading && !customer) || referralCodeGenerationError) return <ErrorPlaceholder />;

  if (!customerLoading && !!customer) {
    return (
      <CSSTransition in={ready} timeout={transitionTimeout} classNames="fade" unmountOnExit>
        <StyledReferralCard>
          {/* renders referral steps */}
          <VitoloReferralSteps />

          {/* renders referral share section */}
          <VitoloReferralShare customer={customer} />

          <Separator />

          <StyledReferralFooterText variant="p">
            <FormattedMessage id="referral.page.privacy.text" />
          </StyledReferralFooterText>

          <StyledReferralFooterText variant="p">
            <FormattedMessage
              id="referral.page.terms_and_conditions.text"
              values={{
                a: (chunk) => renderAnchorTag(chunk, VITOLO_REFERRAL_TERMS_LINK),
              }}
            />
          </StyledReferralFooterText>
        </StyledReferralCard>
      </CSSTransition>
    );
  }

  return null;
}

export default VitoloReferralInformation;
