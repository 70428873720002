import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useIntl, FormattedMessage } from 'react-intl';
import { CSSTransition } from 'react-transition-group';
import { useHistory } from 'react-router-dom';
import { Typography, Link, Container, Paper, Button } from '@mui/material';

import useInsuranceInfo from '../../hooks/useInsuranceInfo';
import { showSelfCancellationMap } from '../../utils/mappings';
import {
  GET_CUSTOMER,
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_PETOLO,
  INSURANCE_TYPE_VITOLO,
  useReady,
} from '../../shared';

import ButtonContainer from '../../components/ButtonContainer';
import { BackButton, PageTitle } from '../../components/common';
import { ErrorPlaceholder } from '../../components/ui';
import fetchPolicyCoverageInfo from '../../actions/PolicyActions';

import {
  Premium,
  Conditions,
  AdditionalAdvantagesPetolo,
  AdditionalAdvantages,
  MainDetails,
  AddOns,
} from './PolicyDetailsItems';

import { StyledContractCancelLink, StyledVerticalContent } from './PolicyInfo.styled';

const renderLink = (chunk, href) => (
  <Link variant="p" href={href}>
    {chunk}
  </Link>
);

export default function PolicyInfo() {
  const ready = useReady();
  const intl = useIntl();
  const history = useHistory();

  const { data: customerData, loading: customerLoading } = useQuery(GET_CUSTOMER);
  const { customer } = customerData || {};

  const [policyCoverageInfo, setPolicyCoverageInfo] = useState(null);

  const { type: insuranceType } = useInsuranceInfo();
  const contractNotCancelled = !customer?.contract?.cancelationDate;

  const isDentolo = insuranceType === INSURANCE_TYPE_DENTOLO;
  const isPetolo = insuranceType === INSURANCE_TYPE_PETOLO;
  const isVitolo = insuranceType === INSURANCE_TYPE_VITOLO;

  const {
    contractPremium,
    contractBudgetSchedule,
    addonBudgedSchedule: budgetScheduleAddon,
  } = customer?.contract || {};

  const budgetAmountInfo = budgetScheduleAddon
    ? budgetScheduleAddon?.limits
    : contractBudgetSchedule;

  const {
    single_page: showInSinglePage,
    addons: policyCoverageAddons,
    budget: policyCoverageBudget,
    conditions: policyCoverageConditions,
    main: policyCoverageMain,
    premium: policyCoveragePremium,
    endNote: policyCoverageEndNote,
    additional_advantages: policyCoverageAdditionalAdvantages,
    additional_advantages_petolo: policyCoverageAdditionalAdvantagesPetolo,
    additional_advantages_vitolo: policyCoverageAdditionalAdvantagesVitolo,
  } = policyCoverageInfo || {};

  const showContractCancelLink = showSelfCancellationMap[`${insuranceType}`];

  useEffect(() => {
    if (!customerLoading && !!customer) {
      const locale = intl?.locale;
      const policyCategory = customer?.contract?.policyCategory;
      const imCoverage = customer?.contract?.imCoverage;
      fetchPolicyCoverageInfo(policyCategory, imCoverage, locale).then(setPolicyCoverageInfo);
    }
  }, [customerLoading, customer, intl]);

  if (customerLoading) return null;

  if (customer?.contract) {
    return (
      <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
        <Container>
          <BackButton to="/dashboard" />

          <PageTitle>
            <Typography variant="h1">
              <FormattedMessage id="policy.covergae_info.title" />
            </Typography>
          </PageTitle>

          <Paper>
            <StyledVerticalContent>
              <AddOns details={policyCoverageAddons} />
              <MainDetails details={policyCoverageMain} />

              {isDentolo && <AdditionalAdvantages details={policyCoverageAdditionalAdvantages} />}

              {isPetolo && (
                <AdditionalAdvantagesPetolo details={policyCoverageAdditionalAdvantagesPetolo} />
              )}

              {isVitolo && (
                <AdditionalAdvantagesPetolo details={policyCoverageAdditionalAdvantagesVitolo} />
              )}

              {showInSinglePage && (
                <>
                  <Conditions
                    conditions={policyCoverageConditions}
                    budgetInfo={policyCoverageBudget}
                    budgetAmountInfo={budgetAmountInfo}
                    hasAddon={!!budgetScheduleAddon}
                  />

                  <Premium premiumInfo={policyCoveragePremium} contractPremium={contractPremium} />
                </>
              )}

              {policyCoverageEndNote && (
                <Typography variant="p">{policyCoverageEndNote}</Typography>
              )}
            </StyledVerticalContent>

            {!showInSinglePage && isDentolo && (
              <ButtonContainer>
                <Button variant="outlined" onClick={() => history.push('/policy-details')}>
                  <Typography variant="buttons">
                    <FormattedMessage id="policy.covergae_info.all_details.button" />
                  </Typography>
                </Button>
              </ButtonContainer>
            )}
          </Paper>

          {showContractCancelLink && contractNotCancelled && (
            <StyledContractCancelLink>
              <Typography variant="p" style={{ marginRight: '0.2rem' }}>
                <FormattedMessage
                  id="policy.cancellation.text"
                  values={{
                    a: (chunk) => renderLink(chunk, '/contract-cancellation'),
                  }}
                />
              </Typography>
            </StyledContractCancelLink>
          )}
        </Container>
      </CSSTransition>
    );
  }
  return <ErrorPlaceholder />;
}
