import React from 'react';
import { useQuery } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import { CSSTransition } from 'react-transition-group';
import { Container, Paper, Typography } from '@mui/material';

import useInsuranceInfo from '../../hooks/useInsuranceInfo';
import { GET_CCDOCUMENTS, INSURANCE_TYPE_DENTOLO } from '../../shared';
import { BackButton, PageTitle } from '../common';
import { DownloadLink, ErrorPlaceholder } from '../ui';

import DentoloPaymentOverviewHelpContent from './DentoloPaymentOverviewHelpContent';

import { StyledVerticalContent } from './PaymentOverview.styled';

export default function PaymentOverview() {
  const intl = useIntl();
  const { type: insuranceType } = useInsuranceInfo();

  const {
    data: ccdocsData,
    loading: ccdocsLoading,
    error: ccdocsError,
  } = useQuery(GET_CCDOCUMENTS);

  if (ccdocsError) return <ErrorPlaceholder />;

  return (
    <CSSTransition
      in={ccdocsData?.contributionConfirmationsDocuments?.length >= 0}
      timeout={400}
      classNames="slow-fade"
      unmountOnExit
    >
      <Container>
        <BackButton to="/" />

        <PageTitle>
          <Typography variant="h1">
            <FormattedMessage id="payment.overview.heading" />
          </Typography>
        </PageTitle>

        <StyledVerticalContent>
          <Typography variant="p">
            <FormattedMessage id="payment.overview.download.certificate" />
          </Typography>

          <Paper>
            {!ccdocsLoading && ccdocsData?.contributionConfirmationsDocuments?.length > 0 ? (
              ccdocsData.contributionConfirmationsDocuments.map((confirmation) => (
                <DownloadLink
                  key={confirmation?.id}
                  name={intl.formatMessage(
                    { id: 'payment.overview.contribution.document' },
                    {
                      year: confirmation?.year || '',
                    }
                  )}
                  link={confirmation?.url}
                  target="_blank"
                >
                  <FormattedMessage
                    id="payment.overview.contribution.document"
                    values={{ year: confirmation?.year || '' }}
                  />
                </DownloadLink>
              ))
            ) : (
              <Typography variant="p" textAlign="center">
                <FormattedMessage id="payment.overview.message.no_contribution" />
              </Typography>
            )}
          </Paper>

          {insuranceType === INSURANCE_TYPE_DENTOLO && <DentoloPaymentOverviewHelpContent />}
        </StyledVerticalContent>
      </Container>
    </CSSTransition>
  );
}
