import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useIntl, FormattedMessage } from 'react-intl';
import { CSSTransition } from 'react-transition-group';
import { Typography, Link, Container } from '@mui/material';

import useInsuranceInfo from '../../hooks/useInsuranceInfo';
import useLocalStorage from '../../hooks/useLocalStorage';
import useVitoloNPSSurvey from './useVitoloNPSSurvey';
import useShowOnboarding from './useShowOnboarding';
import useShowContributionConfirmationDocumentNotification from './useShowContributionConfirmationDocumentNotification';
import useIdentifyHotjarUser from './useIdentifyHotjarUser';

import ClaimStatusNotifications from '../../components/ClaimStatusNotifications';
import { ErrorPlaceholder, PolicyCard } from '../../components/ui';
import { Notification, PageTitle } from '../../components/common';
import { GET_CLAIMS, GET_CUSTOMER, GET_PAYMENT, getFailedPayments, useReady } from '../../shared';

import { StyledNotificationText } from '../../components/styled/Notification.styled';
import fetchPolicyCoverageInfo from '../../actions/PolicyActions';
import { parseContractStatusInfo } from './contractStatusUtils';
import { showRimburseMap, showSelfCancellationMap } from '../../utils/mappings';

import LinkToClaimsPage from './LinkToClaimsPage';
import PromotionCarousel from '../promotions/PromotionCarousel';
import InsureAnotherPetBanner from '../../components/Banners/InsureAnotherPetBanner/InsureAnotherPetBanner';
import MissingIban from '../../components/PolicyInfo/MissingIban';
import CustomerDetailsEditFormWrapper from '../contractManagement/CustomerDetails/CustomerDetailsEditFormWrapper';
import BankDetailsEditor from '../contractManagement/CustomerDetails/BankDetailsEditor';

import {
  StyledContractCancelLink,
  StyledDashbaordTitleContainer,
  StyledTag,
  StyledMissingIbanBannerContainer,
  StyledNotificationsContainer,
} from './Dashboard.styled';

const renderLink = (chunk, href) => (
  <Link variant="p" href={href}>
    {chunk}
  </Link>
);

export default function Dashboard() {
  const ready = useReady();
  const intl = useIntl();
  const history = useHistory();

  const [getFromLocalStorage, saveToLocalStorage] = useLocalStorage();

  const { type: insuranceType, isPatolo } = useInsuranceInfo();

  const { data: customerData, loading: customerLoading } = useQuery(GET_CUSTOMER);
  const { digitalPaymentMethod, customer } = customerData || {};
  const {
    bankAccountFirstName,
    bankAccountLastName,
    iban,
    key,
    uuid,
    contract: { billingDay } = {},
  } = customer || {};

  const { data: paymentsData, loading: paymentsLoading } = useQuery(GET_PAYMENT);
  const { data: claimsData, loading: claimsLoading } = useQuery(GET_CLAIMS);

  const [policyCoverageInfo, setPolicyCoverageInfo] = useState(null);
  const [contractStatusInfo, setContractStatusInfo] = useState({});
  const [failedPayments, setFailedPayments] = useState([]);
  // TODO: dentalMissingIban when the story of dentolo is ready
  const [missingIban, setMissingIban] = useState(true);
  const [showBankDetailsEditor, setShowBankDetailsEditor] = useState(false);

  const { active: hasActiveDigitalPaymentMethod } = digitalPaymentMethod || {};

  const contractNotCancelled = !customer?.contract?.cancelationDate;

  useEffect(() => {
    const isContractMissingIbanExistsInStorage = getFromLocalStorage(
      `customer-missing-iban-${key}`
    );

    setMissingIban(isContractMissingIbanExistsInStorage);
  }, [getFromLocalStorage, key]);

  useEffect(() => {
    const filteredFailedPayments = getFailedPayments(paymentsData?.payments);

    if (filteredFailedPayments?.length) {
      setFailedPayments(filteredFailedPayments);
    }
  }, [paymentsData, paymentsLoading]);

  useEffect(() => {
    if (!customerLoading && !!customer) {
      const locale = intl?.locale;
      const policyCategory = customer?.contract?.policyCategory;
      const imCoverage = customer?.contract?.imCoverage;
      fetchPolicyCoverageInfo(policyCategory, imCoverage, locale).then(setPolicyCoverageInfo);

      const { startingAt, cancelationDate, status, addOnTrialMonth } = customer?.contract || {};
      setContractStatusInfo(
        parseContractStatusInfo(startingAt, cancelationDate, status, addOnTrialMonth)
      );
    }
  }, [customerLoading, customer, intl]);

  useIdentifyHotjarUser(uuid);

  useVitoloNPSSurvey();

  useShowOnboarding();

  const { showCCDocumentNotification, setCCDocumentSeen, dismissCCDocumentNotification } =
    useShowContributionConfirmationDocumentNotification();

  // TODO: dismissDentalMissingIban when the story of dentolo is ready
  const dismissMissingIbanNotification = () => {
    saveToLocalStorage(`customer-missing-iban-${key}`, true);
    setMissingIban(true);
  };

  const showReimbursementCard = showRimburseMap[`${insuranceType}`];
  const showContractCancelLink = showSelfCancellationMap[`${insuranceType}`];

  return (
    <>
      <Container>
        <StyledNotificationsContainer>
          <ClaimStatusNotifications />

          {!!showCCDocumentNotification && (
            <Notification type="success" onDismiss={dismissCCDocumentNotification}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link
                variant="nostyle"
                component="button"
                onClick={() => setCCDocumentSeen().then(() => history.push('/payment-overview'))}
              >
                <StyledNotificationText $title>
                  <Typography variant="h3">
                    <FormattedMessage id="payment.overview.notification.title" />
                  </Typography>
                </StyledNotificationText>

                <StyledNotificationText>
                  <Typography variant="p">
                    <FormattedMessage id="payment.overview.notification.text" />
                  </Typography>
                </StyledNotificationText>
              </Link>
            </Notification>
          )}
        </StyledNotificationsContainer>

        {!customerLoading && customer?.contract && (
          <>
            <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
              <>
                <StyledDashbaordTitleContainer>
                  <PageTitle>
                    <Typography variant="h1">
                      <FormattedMessage id="dashbaord.title" />
                    </Typography>
                  </PageTitle>

                  <div>
                    {contractStatusInfo?.label && !failedPayments?.length && (
                      <StyledTag type={insuranceType} status={contractStatusInfo?.statusColor}>
                        <Typography variant="p">{contractStatusInfo?.label}</Typography>
                      </StyledTag>
                    )}
                  </div>
                </StyledDashbaordTitleContainer>

                {!iban && hasActiveDigitalPaymentMethod && !missingIban && (
                  <StyledMissingIbanBannerContainer>
                    <MissingIban
                      onDismiss={dismissMissingIbanNotification}
                      title={intl.formatMessage({ id: 'policy.info.missing.iban.title' })}
                      message1={intl.formatMessage({ id: 'policy.info.missing.iban.message1' })}
                      message2={intl.formatMessage({ id: 'policy.info.missing.iban.message2' })}
                      linkText={intl.formatMessage({ id: 'policy.info.missing.iban.link' })}
                      onClick={() => setShowBankDetailsEditor(true)}
                    />
                  </StyledMissingIbanBannerContainer>
                )}

                <PolicyCard
                  customer={customer}
                  addons={policyCoverageInfo?.addons}
                  contractStatusInfo={contractStatusInfo}
                />

                {!iban && hasActiveDigitalPaymentMethod && missingIban && (
                  <StyledMissingIbanBannerContainer>
                    <MissingIban
                      title={intl.formatMessage({ id: 'policy.info.missing.iban.title' })}
                      message1={intl.formatMessage({ id: 'policy.info.missing.iban.message1' })}
                      message2={intl.formatMessage({ id: 'policy.info.missing.iban.message2' })}
                      linkText={intl.formatMessage({ id: 'policy.info.missing.iban.link' })}
                      onClick={() => setShowBankDetailsEditor(true)}
                    />
                  </StyledMissingIbanBannerContainer>
                )}

                {showReimbursementCard &&
                  !claimsLoading &&
                  Array.isArray(claimsData.claims) &&
                  claimsData.claims.length <= 0 && <LinkToClaimsPage />}

                {/* hide carousel for patolo contract */}
                {!isPatolo && <PromotionCarousel />}

                {showContractCancelLink && contractNotCancelled && (
                  <StyledContractCancelLink>
                    <Typography variant="p">
                      <FormattedMessage
                        id="policy.cancellation.text"
                        values={{
                          a: (chunk) => renderLink(chunk, '/contract-cancellation'),
                        }}
                      />
                    </Typography>
                  </StyledContractCancelLink>
                )}
              </>
            </CSSTransition>

            {!isPatolo && <InsureAnotherPetBanner customer={customer} />}

            {!iban && (
              <CustomerDetailsEditFormWrapper
                isOpen={showBankDetailsEditor}
                title={intl.formatMessage({ id: 'bank.details.add.missed.iban.modal.title' })}
                missingIban
                hasActiveDigitalPaymentMethod={hasActiveDigitalPaymentMethod}
                onClose={() => setShowBankDetailsEditor(false)}
                data={{
                  firstName: bankAccountFirstName,
                  lastName: bankAccountLastName,
                  iban,
                  billingDay,
                }}
                formComponent={<BankDetailsEditor />}
              />
            )}
          </>
        )}
      </Container>

      {!customerLoading && !customer?.contract && <ErrorPlaceholder />}
    </>
  );
}
