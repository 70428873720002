import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import {
  USER_CLAIMS_ONBOARD_KEY,
  GET_CLAIMS,
  GET_PAYMENT,
  CLEAR_CLAIM_NOTIFICATION,
  claimStatus,
} from '../shared';

import { Link } from './ui';
import { Notification } from './common';
import { StyledNotificationText } from './styled/Notification.styled';

const getNotificationType = (status) => {
  switch (status) {
    case claimStatus.NEW:
      return 'info';
    case claimStatus.WAITING_FOR_INFORMATION:
    case claimStatus.BLOCKED:
      return 'warning';
    case claimStatus.REIMBURSEMENT_ACKNOWLEDGED:
    case claimStatus.PARTIALLY_CLOSED:
    case claimStatus.CLOSED:
      return 'success';
    case claimStatus.DECLINED:
      return 'processing';
    default:
      return 'info';
  }
};

const skipUserClaimsOnboarding = () => {
  if (window?.localStorage) {
    window.localStorage.setItem(USER_CLAIMS_ONBOARD_KEY, 'skip');
  }
};

const ClaimStatusNotifications = () => {
  const [notificationItems, setNotificationItems] = useState([]);
  const [clearClaimNotification] = useMutation(CLEAR_CLAIM_NOTIFICATION, {
    refetchQueries: [{ query: GET_CLAIMS }],
  });

  const clearNotification = (claim) => {
    const newNotificationItems = notificationItems.filter((c) => c?.key !== claim?.key);

    if (claim?.id) {
      clearClaimNotification({
        variables: {
          claimId: claim?.id,
        },
      });
    }

    setNotificationItems(newNotificationItems);
  };

  const getPayments = useQuery(GET_PAYMENT);
  const payments = getPayments?.data?.payments;
  const hasFailedPayments = Array.isArray(payments)
    ? payments.find((item) => item?.status === 'failed')
    : null;

  const getClaims = useQuery(GET_CLAIMS);

  useEffect(() => {
    const claims = getClaims?.data?.claims || [];

    if (!hasFailedPayments && claims.length > 0) {
      const newClaims = claims.filter((c) => !!c?.statusUpdated);

      const waitingForDocuments = newClaims.find(
        (c) => c?.status === claimStatus.WAITING_FOR_INFORMATION
      );
      const completed = newClaims.find((c) => c?.status === claimStatus.CLOSED);
      const reimbursementPromised = newClaims.find(
        (c) => c?.status === claimStatus.REIMBURSEMENT_ACKNOWLEDGED
      );
      const partialPayment = newClaims.find((c) => c?.status === claimStatus.PARTIALLY_CLOSED);
      const declined = newClaims.find((c) => c?.status === claimStatus.DECLINED);
      const newlyCreated = newClaims.find((c) => c?.status === claimStatus.NEW);
      const blocked = newClaims.find((c) => c?.status === claimStatus.BLOCKED);

      const claimsInSortedOrder = [
        waitingForDocuments,
        completed,
        reimbursementPromised,
        partialPayment,
        declined,
        newlyCreated,
        blocked,
      ];
      const filteredClaims = claimsInSortedOrder.filter((c) => !!c);
      setNotificationItems(filteredClaims);
    } else {
      setNotificationItems([]);
    }
  }, [getClaims, hasFailedPayments]);

  return (
    <>
      {notificationItems.slice(0, 2).map((claim) => {
        const { id, status } = claim || {};

        const dismiss = () => clearNotification(claim);

        return (
          <Notification type={getNotificationType(status)} onDismiss={dismiss} key={id}>
            <Link
              variant="nostyle"
              to={`/user-claims-info/${id}`}
              onClick={skipUserClaimsOnboarding}
            >
              <StyledNotificationText $title>
                <Typography variant="h3">
                  <FormattedMessage id={`claim_status_notifications.heading.${status}`} />
                </Typography>
              </StyledNotificationText>

              <StyledNotificationText>
                <Typography variant="p">
                  <FormattedMessage id={`claim_status_notifications.content.${status}`} />
                </Typography>
              </StyledNotificationText>
            </Link>
          </Notification>
        );
      })}
    </>
  );
};

export default ClaimStatusNotifications;
