import { css, styled } from '@mui/material';

import { imgLinks, INSURANCE_TYPE_VITOLO } from '../../../shared';
import { MIN_WIDTH_MD } from '../../../theme/breakpoints';

import { ButtonContainer } from '../../../components/common';

export const StyledImageContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin-bottom: 0.5em;
  margin-top: 1em;

  @media (min-width: ${MIN_WIDTH_MD}) {
    margin-top: 2em;
  }
`;

export const StyledDesktopImage = styled('img')`
  display: none;

  @media (min-width: ${MIN_WIDTH_MD}) {
    display: block;
  }
`;

export const StyledMobileImage = styled('img')`
  display: block;

  @media (min-width: ${MIN_WIDTH_MD}) {
    display: none;
  }
`;

export const StyledButtonContainer = styled(ButtonContainer)`
  display: flex;
  flex-direction: column-reverse;
  row-gap: 1em;
  margin-bottom: 0;

  @media (min-width: ${MIN_WIDTH_MD}) {
    flex-direction: row-reverse;
    column-gap: 1em;
  }
`;

export const StyledLandingPageNormalText = styled('div')`
  text-align: center;
  margin-bottom: 1em;
  padding: 0 1em;
`;

const vitoloCancellationDateBackground = css`
  background-image: url(${imgLinks['icon.vitolo.underline']});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  padding-bottom: 0.125em;
`;

export const StyledCancellationDate = styled(StyledLandingPageNormalText)(
  ({ theme, type }) => css`
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding: 0 8px;
    background: ${theme.palette.background.cancellationDate};
    ${type === INSURANCE_TYPE_VITOLO && vitoloCancellationDateBackground};
    color: ${theme.palette.main.professional_blue};
    border-radius: 3px;
  `
);
