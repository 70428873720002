import { styled } from '@mui/material';

import { StyledReferralCoverImageContainer } from '../ReferralPage.styled';

export const StyledDentoloReferralStatusEmptyContainer = styled('div')`
  text-align: center;
`;

export const StyledDentoloReferralStatusEmptyImageContainer = styled(
  StyledReferralCoverImageContainer
)`
  margin: 1rem auto 2.25rem;
  padding: 0;
  width: 260px;
  height: 100px;
  overflow: hidden;
`;

export const StyledDentoloReferralStatusEmptyImg = styled('img')`
  max-width: 100%;
  width: 100%;
  height: auto;
`;
