import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import { Typography } from '@mui/material';

import useConsole from '../../../../hooks/useConsole';
import { getReferralShareToSocialList } from '../referralPageHelper';
import { GETOLO_UTM_PARAMS } from '../../../../shared';
import {
  elementIdWithReferralLink,
  getVitoloReferralUrl,
  socialItemsData,
} from './vitoloReferral.helper';

import {
  StyledReferralCardContent,
  StyledReferralCopyLinkButton,
  StyledReferralCopyLinkOrSocialsText,
  StyledReferralIdBox,
  StyledReferralIdContainer,
  StyledReferralIdTitle,
  StyledReferralText,
  StyledSocialGroup,
  StyledSocialImg,
  StyledSocialLinksContainer,
} from '../ReferralPage.styled';

const utmSource = `utm_source=${GETOLO_UTM_PARAMS.source}`;
const utmCampaign = `utm_campaign=${GETOLO_UTM_PARAMS.campaignReferral}`;

// replaces <b /> in the translation Id
const renderBTag = (chunk) => <strong>{chunk}</strong>;

function VitoloReferralShare(props) {
  const { customer } = props;

  const intl = useIntl();
  const tooltipNodeRef = useRef(null);
  const { consoleError } = useConsole();

  const refId = customer?.referralCode;
  const policyCategory = customer?.contract?.policyCategory;

  const vitoloReferralUrlToUse = getVitoloReferralUrl(policyCategory);
  const vitoloReferralUrlWithUtmParams = `${vitoloReferralUrlToUse}&${utmSource}&${utmCampaign}`;
  const [refUrl, setRefUrl] = useState(vitoloReferralUrlWithUtmParams);

  // Get social items to share the referral link
  const shareSocialItems = getReferralShareToSocialList(socialItemsData);

  const shareData = {
    fb: {
      refUrl,
      elementIdWithReferralLink,
    },
    whatsapp: {
      intl,
      refUrl,
      elementIdWithReferralLink,
      waMessageId: 'referral.page.vitolo.whatsapp.message',
    },
    email: {
      intl,
      refUrl,
      elementIdWithReferralLink,
      emailContentIds: {
        to: '',
        subjectId: 'referral.page.vitolo.email.subject',
        bodyId: 'referral.page.vitolo.email.message',
      },
    },
  };

  // Copy to clipboard
  const onLinkCopy = async () => {
    if (!window?.navigator?.clipboard) return;

    const { current: btnCopyLink } = tooltipNodeRef || {};
    const linkToCopy = btnCopyLink?.dataset?.referralLink;

    if (!btnCopyLink || !linkToCopy) return;

    try {
      await window.navigator.clipboard.writeText(linkToCopy);

      ReactTooltip.show(btnCopyLink);

      setTimeout(() => ReactTooltip.hide(btnCopyLink), 1200);
    } catch (err) {
      consoleError('Could not copy text: ', err);
    }
  };

  // Add referral `id` to the referral url
  useEffect(() => {
    if (refId && refUrl.indexOf('&refid=') < 0) {
      setRefUrl(`${refUrl}&refid=${refId}`);
    }
  }, [refId, refUrl]);

  return (
    <>
      <StyledReferralCardContent>
        <StyledReferralCopyLinkButton
          variant="primary"
          id={elementIdWithReferralLink}
          data-for="link-copied"
          data-tip
          data-event="none"
          ref={tooltipNodeRef}
          data-referral-link={refUrl}
          data-testid="referral_button_copylink_vitolo"
          onClick={onLinkCopy}
        >
          <Typography variant="buttons">
            <FormattedMessage id="referral.page.copy.button" />
          </Typography>
        </StyledReferralCopyLinkButton>

        <ReactTooltip id="link-copied" className="u3-referral-tooltip" effect="solid">
          <FormattedMessage id="referral.page.link.copied" />
        </ReactTooltip>
      </StyledReferralCardContent>

      <StyledReferralCopyLinkOrSocialsText variant="h3">
        <FormattedMessage id="referral.page.share_in_socials" />
      </StyledReferralCopyLinkOrSocialsText>

      <StyledSocialLinksContainer>
        <StyledSocialGroup>
          {shareSocialItems.map((socialItem) => (
            <StyledSocialImg
              key={socialItem.id}
              src={socialItem.image}
              alt={socialItem.id}
              data-testid={socialItem.testId}
              onClick={() => socialItem.click(shareData[socialItem.id])}
            />
          ))}
        </StyledSocialGroup>
      </StyledSocialLinksContainer>

      <StyledReferralIdContainer>
        <StyledReferralText variant="p">
          <FormattedMessage
            id="referral.page.referral_id.description"
            values={{
              product: 'vitolo',
              insurance: 'Krankenzusatzversicherung',
              b: (chunk) => renderBTag(chunk),
            }}
          />
        </StyledReferralText>

        <StyledReferralIdTitle variant="p" textAlign="center">
          <FormattedMessage id="referral.page.referral_id.title" />
        </StyledReferralIdTitle>

        <StyledReferralIdBox>
          <Typography variant="p">{refId}</Typography>
        </StyledReferralIdBox>
      </StyledReferralIdContainer>
    </>
  );
}

export default VitoloReferralShare;
