import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { Button, Paper, Typography } from '@mui/material';

import { Radio, RadioLabel, Separator } from '../../../components/common';
import { CHANGE_CANCELATION_REASON, GET_CONTRACT_CANCELLATION_REASONS } from '../../../shared';
import {
  StyledImageContainer,
  StyledCancellationReasonsTitle,
  StyledCancellationReasonsDescription,
  StyledButtonContainer,
  StyledRadioContainer,
  StyledReason,
} from './CancellationReasons.styled';

export default function CancellationReasons({
  pageTitleId,
  pageDescriptionId,
  pageIcon,
  pageIconAlt,
}) {
  const history = useHistory();

  const [reason, setReason] = useState('');

  const { data } = useQuery(GET_CONTRACT_CANCELLATION_REASONS, {
    fetchPolicy: 'network-only',
  });
  const cancellationReasons = data?.cancellationAvailableReasons?.reasons;

  const [changeCancelationReason] = useMutation(CHANGE_CANCELATION_REASON, {
    variables: {
      cancelationReason: reason,
    },
  });

  const handleButtonClick = () => {
    if (reason) {
      changeCancelationReason().then(() => {
        history.push('/contract-cancellation/success');
      });
    }
  };

  return (
    <Paper>
      {pageIcon && (
        <StyledImageContainer>
          <img src={pageIcon} alt={pageIconAlt} />
        </StyledImageContainer>
      )}

      <StyledCancellationReasonsTitle>
        <Typography variant="h3">
          <FormattedMessage id={pageTitleId} />
        </Typography>
      </StyledCancellationReasonsTitle>

      <Separator />

      <StyledCancellationReasonsDescription>
        <Typography variant="p">
          <FormattedMessage id={pageDescriptionId} />
        </Typography>
      </StyledCancellationReasonsDescription>

      <StyledRadioContainer>
        {cancellationReasons &&
          Object.keys(cancellationReasons).map((item) => (
            <StyledReason key={item} onClick={() => setReason(item)}>
              <Radio
                name="reason"
                onChange={(e) => {
                  e.stopPropagation();
                  setReason(item);
                }}
                value={item}
                checked={item === reason}
              />
              <RadioLabel style={{ marginLeft: '1rem' }}>
                <Typography variant="p" color="professional_blue">
                  {cancellationReasons[`${item}`]}
                </Typography>
              </RadioLabel>
            </StyledReason>
          ))}
      </StyledRadioContainer>

      <Separator />

      <StyledButtonContainer>
        <Button variant="outlined" onClick={() => history.push('/')}>
          <Typography variant="buttons">
            <FormattedMessage id="cancellation.contract.button.backToHome" />
          </Typography>
        </Button>

        <Button disabled={!reason} onClick={() => handleButtonClick()}>
          <Typography variant="buttons">
            <FormattedMessage id="cancellation.contract.button.send" />
          </Typography>
        </Button>
      </StyledButtonContainer>
    </Paper>
  );
}
