import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { CSSTransition } from 'react-transition-group';
import { Typography } from '@mui/material';

import { imgLinks, useReady } from '../../../../shared';
import { GET_REFERRAL_LIST } from '../../../../graphql/queries/referrals';

import ReferralList from '../ReferralList';
import ReferralStatusDescription from '../ReferralStatusDescription';
import { Loading } from '../../../../components/ui';
import { StyledReferralCard, StyledReferralStatusTitle } from '../ReferralPage.styled';
import {
  StyledVitoloReferralStatusEmptyContainer,
  StyledVitoloReferralStatusEmptyImageContainer,
  StyledVitoloReferralStatusEmptyImg,
} from './VitoloReferral.styled';

const referralStatusDescription = [
  {
    id: 1,
    formattedDescription: (
      <FormattedMessage
        id="referral.status.description.pending"
        values={{ b: (chunks) => <strong>{chunks}</strong> }}
      />
    ),
  },
  {
    id: 2,
    formattedDescription: (
      <FormattedMessage
        id="referral.status.description.cancelled"
        values={{ product: 'vitolo', b: (chunks) => <strong>{chunks}</strong> }}
      />
    ),
  },
  {
    id: 3,
    formattedDescription: (
      <FormattedMessage
        id="referral.status.description.completed"
        values={{
          amount: 50,
          giftbrand: 'Amazon Gutschein',
          b: (chunks) => <strong>{chunks}</strong>,
        }}
      />
    ),
  },
];

function VitoloReferralStatus() {
  const ready = useReady();

  const transitionTimeout = 500;

  const { data, loading, refetch } = useQuery(GET_REFERRAL_LIST);
  const { referrals } = data || {};

  useEffect(() => {
    refetch();
  }, [refetch]);

  if (loading) return <Loading showLogo />;

  if ((!loading && referrals?.length <= 0) || !referrals) {
    return <EmptyVitoloReferralStatus />;
  }

  return (
    <CSSTransition in={ready} timeout={transitionTimeout} classNames="fade" unmountOnExit>
      <StyledReferralCard>
        <StyledReferralStatusTitle variant="h2">
          <FormattedMessage id="referral.page.status.title" />
        </StyledReferralStatusTitle>

        <ReferralList referrals={referrals} />

        <ReferralStatusDescription
          titleId="referral.status.description.title"
          statusDescription={referralStatusDescription}
        />
      </StyledReferralCard>
    </CSSTransition>
  );
}

export default VitoloReferralStatus;

const EmptyVitoloReferralStatus = () => {
  return (
    <StyledVitoloReferralStatusEmptyContainer>
      <Typography variant="h2">
        <FormattedMessage id="referral.page.status.empty.title" />
      </Typography>

      <StyledVitoloReferralStatusEmptyImageContainer>
        <StyledVitoloReferralStatusEmptyImg
          src={imgLinks['image-cover-vitolo-referral-empty']}
          alt="vitolo referral"
        />
      </StyledVitoloReferralStatusEmptyImageContainer>

      <Typography variant="p">
        <FormattedMessage
          id="referral.page.status.empty.description"
          values={{ product: 'vitolo' }}
        />
      </Typography>
    </StyledVitoloReferralStatusEmptyContainer>
  );
};
