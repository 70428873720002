import React from 'react';
import { useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { CSSTransition } from 'react-transition-group';
import { Link, Typography } from '@mui/material';

import { imgLinks, useReady } from '../../../../shared';
import { GET_REFERRAL_LIST } from '../../../../graphql/queries/referrals';

import ReferralList from '../ReferralList';
import ReferralStatusDescription from '../ReferralStatusDescription';
import { Loading } from '../../../../components/ui';
import {
  StyledReferralCard,
  StyledReferralMoreInformationContainer,
  StyledReferralMoreInformationText,
  StyledReferralStatusTitle,
} from '../ReferralPage.styled';
import {
  StyledPetoloReferralStatusEmptyContainer,
  StyledPetoloReferralStatusEmptyImageContainer,
  StyledPetoloReferralStatusEmptyImg,
} from './PetoloReferral.styled';

const referralStatusDescription = [
  {
    id: 1,
    formattedDescription: (
      <FormattedMessage
        id="referral.status.description.pending"
        values={{ b: (chunks) => <strong>{chunks}</strong> }}
      />
    ),
  },
  {
    id: 2,
    formattedDescription: (
      <FormattedMessage
        id="referral.status.description.cancelled"
        values={{ product: 'petolo', b: (chunks) => <strong>{chunks}</strong> }}
      />
    ),
  },
  {
    id: 3,
    formattedDescription: (
      <FormattedMessage
        id="referral.status.description.completed"
        values={{
          amount: 50,
          giftbrand: 'Fressnapf-Gutschein',
          b: (chunks) => <strong>{chunks}</strong>,
        }}
      />
    ),
  },
];

const transitionTimeout = 500;

function PetoloReferralStatus({ onReferralInfoLinkClick }) {
  const ready = useReady();

  const { data, loading, refetch } = useQuery(GET_REFERRAL_LIST);
  const { referrals } = data || {};

  React.useEffect(() => {
    refetch();
  }, [refetch]);

  if (loading) return <Loading showLogo />;

  const showEmptyState = !loading && (!Array.isArray(referrals) || referrals.length <= 0);

  return (
    <CSSTransition in={ready} timeout={transitionTimeout} classNames="fade" unmountOnExit>
      <>
        {showEmptyState ? (
          <EmptyPetoloReferralStatus />
        ) : (
          <StyledReferralCard>
            <StyledReferralStatusTitle variant="h2">
              <FormattedMessage id="referral.page.status.title" />
            </StyledReferralStatusTitle>

            <ReferralList referrals={referrals} />

            <ReferralStatusDescription
              titleId="referral.status.description.title"
              statusDescription={referralStatusDescription}
            />
          </StyledReferralCard>
        )}

        <PetoloReferralMoreInformation onReferralInfoLinkClick={onReferralInfoLinkClick} />
      </>
    </CSSTransition>
  );
}

export default PetoloReferralStatus;

const EmptyPetoloReferralStatus = () => {
  return (
    <StyledPetoloReferralStatusEmptyContainer>
      <Typography variant="h2">
        <FormattedMessage id="referral.page.status.empty.title" />
      </Typography>

      <StyledPetoloReferralStatusEmptyImageContainer>
        <StyledPetoloReferralStatusEmptyImg
          src={imgLinks['image.petolo.referral.fressnapf.voucher']}
          alt="petolo referral"
        />
      </StyledPetoloReferralStatusEmptyImageContainer>

      <Typography variant="p">
        <FormattedMessage
          id="referral.page.status.empty.description"
          values={{ product: 'petolo' }}
        />
      </Typography>
    </StyledPetoloReferralStatusEmptyContainer>
  );
};

const renderAnchorTagForMoreInfo = (chunk, fn) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <Link component="button" variant="p" onClick={fn}>
    <Typography variant="p">{chunk}</Typography>
  </Link>
);

const PetoloReferralMoreInformation = ({ onReferralInfoLinkClick }) => {
  return (
    <StyledReferralMoreInformationContainer>
      <Typography variant="h3">
        <FormattedMessage id="referral.page.petolo.more_info.title" />
      </Typography>

      <StyledReferralMoreInformationText variant="p">
        <FormattedMessage
          id="referral.page.petolo.more_info.text"
          values={{
            a: (chunk) => renderAnchorTagForMoreInfo(chunk, onReferralInfoLinkClick),
          }}
        />
      </StyledReferralMoreInformationText>
    </StyledReferralMoreInformationContainer>
  );
};
