import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import ReferralStatusLabel from './ReferralStatusLabel';
import {
  StyledReferraRewardBox,
  StyledReferraRewardText,
  StyledReferralListContainer,
  StyledReferralListItem,
  StyledReferralListItemReferee,
} from './ReferralPage.styled';

const STATUS_COMPLETED = 'sent';

const getReferralCodeTitle = (rewardStoreName) => {
  return <FormattedMessage id="referral.reward.title" values={{ rewardbrand: rewardStoreName }} />;
};

function ReferralList(props) {
  const { referrals } = props;

  if (!referrals || !Array.isArray(referrals)) return null;

  return (
    <>
      <StyledReferralListItem>
        <Typography variant="h3">
          <FormattedMessage id="referral.page.list.title.email" />
        </Typography>

        <Typography variant="h3">
          <FormattedMessage id="referral.page.list.title.status" />
        </Typography>
      </StyledReferralListItem>

      {referrals.map((referral) => {
        const { referee, rewardCouponCode, rewardCouponStoreName, status } = referral;

        const showVoucherCode =
          status === STATUS_COMPLETED && rewardCouponCode && rewardCouponStoreName;

        return (
          <StyledReferralListContainer key={referee?.email}>
            <StyledReferralListItem>
              <StyledReferralListItemReferee variant="p">
                {referee?.email}
              </StyledReferralListItemReferee>

              <ReferralStatusLabel status={status}>
                <FormattedMessage id={`referral.status.label.${status}`} />
              </ReferralStatusLabel>
            </StyledReferralListItem>

            {showVoucherCode && (
              <StyledReferraRewardBox>
                <StyledReferraRewardText variant="p">
                  {getReferralCodeTitle(rewardCouponStoreName)}
                </StyledReferraRewardText>

                <StyledReferraRewardText variant="p">{rewardCouponCode}</StyledReferraRewardText>
              </StyledReferraRewardBox>
            )}
          </StyledReferralListContainer>
        );
      })}
    </>
  );
}

export default ReferralList;
