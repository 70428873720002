import React from 'react';
import { styled, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import useInsuranceInfo from '../../hooks/useInsuranceInfo';
import { INSURANCE_TYPE_DENTOLO, INSURANCE_TYPE_PETOLO, INSURANCE_TYPE_VITOLO } from '../../shared';

import Dentolo404Page from './Dentolo404Page/Dentolo404Page';
import Petolo404Page from './Petolo404Page/Petolo404Page';

const PageNotFound = () => {
  const { type: insuranceType } = useInsuranceInfo();

  const pages404 = {
    [INSURANCE_TYPE_DENTOLO]: <Dentolo404Page />,
    [INSURANCE_TYPE_PETOLO]: <Petolo404Page />,
    [INSURANCE_TYPE_VITOLO]: <TemporaryVitolo404Page />,
  };

  return pages404[`${insuranceType}`] || null;
};

export default PageNotFound;

// This is a temporary 404 page for Vitolo
// It should be replaced with real one in the future
const TemporaryVitolo404Page = () => {
  return (
    <StyledTemporaryVitolo404Container>
      <Typography variant="h1">404</Typography>
      <Typography variant="h1">
        <FormattedMessage id="petolo.404.page.message" />
      </Typography>
    </StyledTemporaryVitolo404Container>
  );
};

const StyledTemporaryVitolo404Container = styled('div')`
  width: 100%;
  text-align: center;
  margin-top: 5rem;
`;
