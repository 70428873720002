import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { imgLinks } from '../../../shared';

import OnboardCard from './OnboardCard';
import {
  StyledOnboardCardParent,
  StyledOnboardCard3Wrapper,
  StyledPin1Image,
  StyledPin2Image,
  StyledPin3Image,
  StyledPin4Image,
  StyledMapImage,
  StyledBoardingArticle,
  StyledDentistImage,
} from './HomeOnboardDentolo.styled';

const HomeOnboardCard3 = ({ titleHtml, bodyText }) => {
  return (
    <OnboardCard titleHtml={titleHtml} bodyText={bodyText}>
      <StyledOnboardCardParent>
        <StyledOnboardCard3Wrapper>
          <StyledPin1Image src={imgLinks['icon.home.onboard.map.pin']} alt="" />
          <StyledPin2Image src={imgLinks['icon.home.onboard.map.pin']} alt="" />
          <StyledPin3Image src={imgLinks['icon.home.onboard.map.pin']} alt="" />
          <StyledPin4Image src={imgLinks['icon.home.onboard.map.pin']} alt="" />
          <StyledMapImage src={imgLinks['icon.home.onboard.map']} alt="" />

          <StyledBoardingArticle>
            <StyledDentistImage src={imgLinks['icon.female.dentist']} alt="" />

            <Typography variant="p">
              <FormattedMessage id="dental_onboard.card_3.body.extra" />
            </Typography>
          </StyledBoardingArticle>
        </StyledOnboardCard3Wrapper>
      </StyledOnboardCardParent>
    </OnboardCard>
  );
};

HomeOnboardCard3.propTypes = {
  titleHtml: PropTypes.string,
  bodyText: PropTypes.string,
};

export default HomeOnboardCard3;
