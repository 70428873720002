import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useParams, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { CSSTransition } from 'react-transition-group';
import { Typography } from '@mui/material';

import { CLOSED } from '../shared/claimStatus';
import useInsuranceInfo from '../hooks/useInsuranceInfo';
import {
  claimStatus,
  CLEAR_CLAIM_NOTIFICATION,
  formatDate,
  GET_CLAIM,
  GET_CLAIMS,
  GET_CUSTOMER,
  INSURANCE_CATEGORY_PET_HEALTH,
  INSURANCE_CATEGORY_PET_LIABILITY,
  INSURANCE_TYPE_PETOLO,
  useClaimStatus,
  useReady,
} from '../shared';

import { BackButton } from './common';
import ClaimContactPerson from '../features/claimDetails/ClaimContactPerson';
import { StyledClaimStatus } from './ui/styled/ClaimStatus.styled';
import AlreadyUploadedDocuments from '../features/alreadyUploadedDocuments';
import {
  ClaimDetailsStatusHistory,
  ClaimDetailsAdditionalPayments,
  ClaimDetailsInfo,
  ErrorPlaceholder,
  Loading,
} from './ui';
import {
  StyledBackButtonContainer,
  StyledClaimDetailsHeaderCard,
  StyledClaimDetailsHeaderContents,
  StyledTreatmentCategory,
  StyledTreatmentInfo,
  StyledHelpCenterParagraph,
} from './styled/UserClaimDetails.styled';

const UserClaimDetails = ({ selectedClaim, enterdElement }) => {
  const ready = useReady();
  const claimStatusBar = useRef(null);
  const [claim, setClaim] = useState(null);
  const [latestPayout, setLatestPayout] = useState(null);
  const [otherPayouts, setOtherPayouts] = useState([]);
  const [claimLabel] = useClaimStatus(claim?.status);
  const { id } = useParams();
  const location = useLocation();
  const { category: insuranceCategory, type: insuranceType } = useInsuranceInfo();

  const { data: customerData, loading: customerLoading } = useQuery(GET_CUSTOMER);

  const { data: claimData, loading: claimLoading } = useQuery(GET_CLAIM, {
    variables: { id: Number(id) },
  });
  const isLoaderShown =
    enterdElement != null && enterdElement?.contains(claimStatusBar?.current) && !claim;
  const [clearClaimNotification] = useMutation(CLEAR_CLAIM_NOTIFICATION, {
    variables: {
      claimId: Number(id),
    },
    refetchQueries: [{ query: GET_CLAIMS }],
  });

  const isPetolo = insuranceType === INSURANCE_TYPE_PETOLO;

  useEffect(() => {
    if (!claimLoading) {
      setClaim(claimData?.claim);
      const claimPayouts = claimData?.claim?.payouts;
      if (claimPayouts?.length) {
        // payouts not yet been confirmed on claims with status auszahlung or ausgezahlt are displayed in the status_detail
        const sortedPayouts = Array.from(claimPayouts).sort(
          (a, b) => new Date(b?.createdAt) - new Date(a?.createdAt)
        );
        setLatestPayout(sortedPayouts[0]);
        setOtherPayouts([...sortedPayouts.slice(1)]);
      }
    }
  }, [claimData, claimLoading]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
    if (claim && claim?.statusUpdated) {
      clearClaimNotification();
    }
  }, [location, clearClaimNotification, claim]);

  const skipHistoryItem = ['cancelled'];

  const showAdditionalPayouts = (status) =>
    status === claimStatus.CLOSED || status === claimStatus.WAITING_FOR_PAYOUT;

  const getClaimDate = () => {
    let date = '';
    if (selectedClaim) {
      date = selectedClaim?.lossDate || selectedClaim?.notificationDate;
    }
    if (claim) {
      date = claim?.lossDate || claim?.notificationDate;
    }
    return formatDate(date);
  };

  const treatmentCategory = selectedClaim
    ? selectedClaim?.treatmentCategory
    : claim?.treatmentCategory;

  const getHelpCenterForClosedClaims = () => {
    if (
      (insuranceCategory === INSURANCE_CATEGORY_PET_LIABILITY ||
        insuranceCategory === INSURANCE_CATEGORY_PET_HEALTH) &&
      (selectedClaim?.status === CLOSED || claim?.status === CLOSED)
    ) {
      return (
        <StyledHelpCenterParagraph>
          <FormattedMessage id="claim.status.details.help.center.text1" />
          &nbsp;
          <a
            target="_blank"
            href="https://support.petolo.de/hc/de/articles/10016698101916-Aus-welchen-Gr%C3%BCnden-wurde-nicht-die-volle-Rechnungssumme-%C3%BCbernommen- "
            rel="noreferrer"
          >
            <FormattedMessage id="claim.status.details.help.center.link" />
          </a>
        </StyledHelpCenterParagraph>
      );
    }

    return '';
  };

  if (!customerLoading && !!customerData && !!Object.keys(customerData).length) {
    return (
      <>
        <CSSTransition
          in={ready && !!(selectedClaim || claim)}
          timeout={450}
          classNames="fade"
          unmountOnExit
        >
          <>
            <StyledBackButtonContainer ref={claimStatusBar}>
              <BackButton to="/user-claims-info" />
            </StyledBackButtonContainer>

            <StyledClaimDetailsHeaderCard>
              <StyledClaimDetailsHeaderContents>
                <StyledTreatmentCategory>
                  {!!treatmentCategory && (
                    <Typography variant="h3">
                      <FormattedMessage id={`treatment.category.${treatmentCategory}`} />
                    </Typography>
                  )}

                  {!treatmentCategory && isPetolo && (
                    <Typography variant="h3">
                      <FormattedMessage id="treatment.category.pet_null" />
                    </Typography>
                  )}
                </StyledTreatmentCategory>

                <StyledTreatmentInfo data-hj-suppress="true">
                  <Typography variant="p">
                    {getClaimDate()} ({selectedClaim ? selectedClaim?.key : claim?.key})
                  </Typography>
                </StyledTreatmentInfo>
              </StyledClaimDetailsHeaderContents>
              <StyledClaimStatus
                claimStatus={selectedClaim ? selectedClaim?.claimLabel : claimLabel}
              >
                <Typography variant="p">
                  <FormattedMessage
                    id={`claim.status.label.${selectedClaim ? selectedClaim?.status : claim?.status}`}
                    tagName="span"
                  />
                </Typography>
              </StyledClaimStatus>
              {getHelpCenterForClosedClaims()}
            </StyledClaimDetailsHeaderCard>
          </>
        </CSSTransition>

        {insuranceCategory !== INSURANCE_CATEGORY_PET_LIABILITY && (
          <CSSTransition in={ready && !!claim} timeout={450} classNames="quick-fade" unmountOnExit>
            <ClaimDetailsInfo
              claimLabel={claimLabel}
              selectedClaim={selectedClaim}
              claim={claim}
              latestPayout={latestPayout}
              customer={customerData?.customer}
            />
          </CSSTransition>
        )}

        {Array.isArray(claimData?.claim?.documentsAttachments) &&
          claimData.claim.documentsAttachments.length > 0 && (
            <CSSTransition
              in={ready && !!claim}
              timeout={450}
              classNames="quick-fade"
              unmountOnExit
            >
              <AlreadyUploadedDocuments
                documentsAttachments={claimData?.claim?.documentsAttachments}
              />
            </CSSTransition>
          )}

        <CSSTransition
          in={ready && !!claim && otherPayouts?.length > 0 && showAdditionalPayouts(claim?.status)}
          timeout={450}
          classNames="fade"
          unmountOnExit
        >
          <ClaimDetailsAdditionalPayments
            otherPayouts={otherPayouts}
            customerIBAN={customerData?.customer?.iban}
          />
        </CSSTransition>

        <CSSTransition
          in={ready && !!claim && !!claim?.externalContact?.externalClaimId}
          timeout={450}
          classNames="fade"
          unmountOnExit
        >
          <ClaimContactPerson claim={claim} />
        </CSSTransition>
        {insuranceCategory !== INSURANCE_CATEGORY_PET_LIABILITY && (
          <CSSTransition in={ready && !!claim} timeout={450} classNames="fade" unmountOnExit>
            <ClaimDetailsStatusHistory claim={claim} skipHistoryItem={skipHistoryItem} />
          </CSSTransition>
        )}

        {isLoaderShown && <Loading showLogo />}
      </>
    );
  }

  if (!customerLoading) {
    return (
      <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
        <ErrorPlaceholder />
      </CSSTransition>
    );
  }

  return null;
};

export default UserClaimDetails;
