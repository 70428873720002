import { styled } from '@mui/material';

import { MIN_WIDTH_MD } from '../../../../theme/breakpoints';

export const StyledVitoloReferralStatusEmptyContainer = styled('div')`
  text-align: center;
`;

export const StyledVitoloReferralStatusEmptyImageContainer = styled('div')`
  margin: 1rem auto;
  padding: 0;
  width: 300px;
  height: auto;
  overflow: hidden;

  @media (min-width: ${MIN_WIDTH_MD}) {
    margin-bottom: 0;
    width: 400px;
  }
`;

export const StyledVitoloReferralStatusEmptyImg = styled('img')`
  max-width: 100%;
  width: 100%;
  height: auto;
`;
