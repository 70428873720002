import { styled } from '@mui/material';
import { MIN_WIDTH_MD } from '../../theme/breakpoints';

export const StyledErrorPlaceholderImg = styled('img')`
  width: 100%;
  margin-left: 0;
  margin-top: 3em;
  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    margin-left: -2em;
    margin-top: 0;
  }
`;

export const StyledFailurePageContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;
  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    flex-direction: row;
    align-items: start;
  }
`;
export const StyledTextContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-bottom: 10rem;
  align-items: center;
  text-align: center;
  h1 {
    padding: 0 1rem 0 1rem;
  }
  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    max-width: 50%;
    align-items: start;
    text-align: left;
    h1 {
      padding: 0;
    }
  }
`;
