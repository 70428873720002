import React from 'react';
import { useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { CSSTransition } from 'react-transition-group';
import { Typography } from '@mui/material';

import { imgLinks, useReady } from '../../../../shared';
import { GET_REFERRAL_LIST } from '../../../../graphql/queries/referrals';

import ReferralList from '../ReferralList';
import ReferralStatusDescription from '../ReferralStatusDescription';
import { Loading } from '../../../../components/ui';
import { StyledReferralCard, StyledReferralStatusTitle } from '../ReferralPage.styled';
import {
  StyledDentoloReferralStatusEmptyContainer,
  StyledDentoloReferralStatusEmptyImageContainer,
  StyledDentoloReferralStatusEmptyImg,
} from './DentoloReferral.styled';

const referralStatusDescription = [
  {
    id: 1,
    formattedDescription: (
      <FormattedMessage
        id="referral.status.description.pending"
        values={{ b: (chunks) => <strong>{chunks}</strong> }}
      />
    ),
  },
  {
    id: 2,
    formattedDescription: (
      <FormattedMessage
        id="referral.status.description.cancelled"
        values={{
          product: 'dentolo',
          b: (chunks) => <strong>{chunks}</strong>,
        }}
      />
    ),
  },
  {
    id: 3,
    formattedDescription: (
      <FormattedMessage
        id="referral.status.description.completed"
        values={{
          amount: 50,
          giftbrand: 'Amazon Gutschein',
          b: (chunks) => <strong>{chunks}</strong>,
        }}
      />
    ),
  },
];

function DentoloReferralStatus() {
  const ready = useReady();

  const transitionTimeout = 500;

  const { data, loading, refetch } = useQuery(GET_REFERRAL_LIST);
  const { referrals } = data || {};

  React.useEffect(() => {
    refetch();
  }, [refetch]);

  if (loading) return <Loading showLogo />;

  if ((!loading && referrals?.length <= 0) || !referrals) {
    return <EmptyDentoloReferralStatus />;
  }

  return (
    <CSSTransition in={ready} timeout={transitionTimeout} classNames="fade" unmountOnExit>
      <StyledReferralCard>
        <StyledReferralStatusTitle variant="h1">
          <FormattedMessage id="referral.page.status.title" />
        </StyledReferralStatusTitle>

        <ReferralList referrals={referrals} />

        <ReferralStatusDescription
          titleId="referral.status.description.title"
          statusDescription={referralStatusDescription}
        />
      </StyledReferralCard>
    </CSSTransition>
  );
}

export default DentoloReferralStatus;

const EmptyDentoloReferralStatus = () => {
  return (
    <StyledDentoloReferralStatusEmptyContainer>
      <Typography variant="h2">
        <FormattedMessage id="referral.page.status.empty.title" />
      </Typography>

      <StyledDentoloReferralStatusEmptyImageContainer>
        <StyledDentoloReferralStatusEmptyImg
          src={imgLinks['image.cover.referral.dental.lg']}
          alt="dentolo referral"
        />
      </StyledDentoloReferralStatusEmptyImageContainer>

      <Typography variant="p">
        <FormattedMessage
          id="referral.page.status.empty.description"
          values={{ product: 'dentolo' }}
        />
      </Typography>
    </StyledDentoloReferralStatusEmptyContainer>
  );
};
