import { styled } from '@mui/material';

import { MIN_WIDTH_MD } from '../../../theme/breakpoints';

import { BackButton } from '../../../components/common';

export const StyledButtonContainer = styled('div')`
  display: none;

  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    display: flex;
    justify-content: center;
    margin-top: 2em;
  }
`;

export const StyledImg = styled('img')`
  width: 100%;
  margin-left: 0;
  margin-top: 5em;
  margin-bottom: 1em;

  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    margin-left: -1em;
    margin-top: 0;
  }
`;
export const StyledBackButton = styled(BackButton)`
  display: flex;
  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    display: none;
  }
`;
