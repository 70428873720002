import { styled } from '@mui/material';

import { MIN_WIDTH_MD } from '../../../theme/breakpoints';

import { ButtonContainer } from '../../../components/common';

export const StyledImageContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin: 0 0 1.5em;
`;

export const StyledCancellationReasonsTitle = styled('h1')`
  text-align: center;
  margin-bottom: 1.5rem;
`;

export const StyledCancellationReasonsDescription = styled('p')`
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
`;

export const StyledButtonContainer = styled(ButtonContainer)`
  display: flex;
  flex-direction: column-reverse;
  row-gap: 1em;

  @media (min-width: ${MIN_WIDTH_MD}) {
    flex-direction: row-reverse;
    column-gap: 1em;
  }
`;

export const StyledRadioContainer = styled('div')`
  margin-top: 1.5rem;
`;

export const StyledReason = styled('div')`
  display: flex;
  word-break: break-word;
  margin-bottom: 0.5rem;
  padding: 1rem;
`;
