import React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { USER_CLAIMS_ONBOARD_KEY } from '../../../shared';
import { Onboard } from '../../ui';
import { Container } from '../../common';

import UserClaimsOnboardCard1 from './UserClaimsOnboardCard1';
import UserClaimsOnboardCard2 from './UserClaimsOnboardCard2';
import UserClaimsOnboardCard3 from './UserClaimsOnboardCard3';
import UserClaimsOnboardCard4 from './UserClaimsOnboardCard4';

const userClaimsOnboardConfig = (intl) => [
  {
    id: 'UserClaimsOnboardCard1',
    name: UserClaimsOnboardCard1,
    titleHtml: intl.formatMessage({ id: 'dental_claims_onboard.card_1.title' }),
    bodyText: intl.formatMessage({ id: 'dental_claims_onboard.card_1.body' }),
    btnText: intl.formatMessage({ id: 'dental_claims_onboard.card_1.button' }),
  },
  {
    id: 'UserClaimsOnboardCard2',
    name: UserClaimsOnboardCard2,
    titleHtml: intl.formatMessage({ id: 'dental_claims_onboard.card_2.title' }),
    bodyText: intl.formatMessage({ id: 'dental_claims_onboard.card_2.body' }),
    btnText: intl.formatMessage({ id: 'dental_claims_onboard.card_2.button' }),
  },
  {
    id: 'UserClaimsOnboardCard3',
    name: UserClaimsOnboardCard3,
    titleHtml: intl.formatMessage({ id: 'dental_claims_onboard.card_3.title' }),
    bodyText: intl.formatMessage({ id: 'dental_claims_onboard.card_3.body' }),
    btnText: intl.formatMessage({ id: 'dental_claims_onboard.card_3.button' }),
  },
  {
    id: 'UserClaimsOnboardCard4',
    name: UserClaimsOnboardCard4,
    titleHtml: intl.formatMessage({ id: 'dental_claims_onboard.card_4.title' }),
    bodyText: intl.formatMessage({ id: 'dental_claims_onboard.card_4.body' }),
    btnText: intl.formatMessage({ id: 'dental_claims_onboard.card_4.button' }),
    btnType: 'primary',
  },
];

const UserClaimsOnboardDentolo = () => {
  const { id } = useParams();
  const intl = useIntl();

  return (
    <Container>
      <Onboard
        initialIndex={id}
        name={USER_CLAIMS_ONBOARD_KEY}
        onboardingUrl="/user-claims/onboarding-dentolo"
        mainPageUrl="/user-claims-info"
        schema={userClaimsOnboardConfig(intl)}
      />
    </Container>
  );
};

export default UserClaimsOnboardDentolo;
